<template>
    <div>
      <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
      <div v-else>
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/overviewPipelineNetwork' }">管网概况</el-breadcrumb-item>
            <el-breadcrumb-item>管网详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div>
          <el-tabs v-model="activeName1" @tab-click="handleClick1">
            <el-tab-pane label="实时数据" name="first"></el-tab-pane>
            <el-tab-pane label="历史数据" name="second"></el-tab-pane>
            <el-tab-pane label="预警记录" name="third"></el-tab-pane>
            <el-tab-pane label="资产信息" name="fourth"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="content">
          <div class="content-top">
            <div class="content-top-left">
              <el-tabs v-model="activeName2" @tab-click="handleClick2">
                <el-tab-pane label="安装示意图" name="shiyitu"></el-tab-pane>
              </el-tabs>
              <div class="bg-img"></div>
            </div>
            <div class="content-top-right">
              <el-tabs v-model="activeName3" @tab-click="handleClick3">
                <el-tab-pane label="基本信息" name="shishishuju"></el-tab-pane>
              </el-tabs>
              <div>
                <div style="overflow-x: scroll;">
                  <table class="chart-table" align="left" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <td class="left-name">监测点名称</td>
                      <td>东环路1号管网</td>
                      <td class="left-name">行政区划</td>
                      <td>定边县</td>
                      <td class="left-name">在线状态</td>
                      <td>在线</td>
                    </tr>
                    <tr>
                      <td class="left-name">位置</td>
                      <td>东环路</td>
                      <td class="left-name">走向</td>
                      <td>南北</td>
                      <td class="left-name">长度</td>
                      <td>500m</td>
                    </tr>
                    <tr>
                      <td class="left-name">管径</td>
                      <td>10</td>
                      <td class="left-name">材质</td>
                      <td>水泥</td>
                      <td class="left-name">壁厚</td>
                      <td>5mm</td>
                    </tr>
                    <tr>
                      <td class="left-name">压力</td>
                      <td>0.5</td>
                      <td class="left-name">流量</td>
                      <td>67</td>
                      <td class="left-name">传感器安装方式</td>
                      <td>蓄电池</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="real-time-curve-wrapper">
            <el-tabs v-model="activeName4" @tab-click="handleClick4">
              <el-tab-pane label="实时曲线" name="shishiquxian"></el-tab-pane>
            </el-tabs>
            <div id="real-time-curve"></div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  export default {
    data() {
      return {
        form: {},
        row: {},
        loading: false,
        activeName1: 'first',
        activeName2: 'shiyitu',
        activeName3: 'shishishuju',
        activeName4: 'shishiquxian',
      };
    },
    methods: {
      handleClick1(tab, event) {
        console.log(tab, event);
      },
      handleClick2(tab, event) {
        console.log(tab, event);
      },
      handleClick3(tab, event) {
        console.log(tab, event);
      },
      handleClick4(tab, event) {
        console.log(tab, event);
      },
      setAnalysisChart() {
        var myChart = echarts.init(document.getElementById('real-time-curve'));
        var option = {
          title: {
            subtext: '单位: Mpa'
          },
          backgroundColor: '',
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            y: 'top',
            data: ['压力', '流量']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00']
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '压力',
              type: 'line',
              stack: 'Total',
              areaStyle: {
                opacity: 0.1
              },
              emphasis: {
                focus: 'series'
              },
              itemStyle: {
                color: '#5CCCFC',
                shadowColor: '#5CCCFC',
              },
              lineStyle: {
                shadowColor: '#5CCCFC',
                shadowBlur: 20,
                color: {
                  type: 'linear',
                  colorStops: [
                    {offset:0,color:'#5CCCFC'},
                    {offset:1,color:'#5CCCFC'},
                  ]
                }
              },
              smooth: true,
              data: [19,50,30,40,70,30]
            },
            // {
            //   name: '流量',
            //   type: 'line',
            //   stack: 'Total',
            //   areaStyle: {
            //     opacity: 0.1
            //   },
            //   emphasis: {
            //     focus: 'series'
            //   },
            //   itemStyle: {
            //     color: '#9690FA',
            //     shadowColor: '#9690FA',
            //   },
            //   lineStyle: {
            //     shadowColor: '#9690FA',
            //     shadowBlur: 20,
            //     color: {
            //       type: 'linear',
            //       colorStops: [
            //         {offset:0,color:'#9690FA'},
            //         {offset:1,color:'#9690FA'},
            //       ]
            //     }
            //   },
            //   smooth: true,
            //   data: [19,81,24,26,52,47]
            // }
          ],
        };
        myChart.setOption(option);
      },
      loadData() {
        this.loading = true
        this.$ajax.post("deviceDetail", {
          id: this.row.id,
        }).then((res) => {
          sessionStorage.setItem("detail", true);
          this.form = res.data;
          this.loading = false
        }).catch(err=>{
          this.loading = false
        })
      },
    },
    mounted() {
      this.setAnalysisChart()
      // if (this.$route.params.row == null) {
      //   this.$router.push('/overviewPipelineNetwork')
      // } else {
      //   this.row = this.$route.params.row
      // }
      // this.loadData()
    },
  };
  </script>
  
  <style lang="less" scoped>
  .mains {
    padding: 400px 0;
    height: 100%;
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 50px;
  }
  .breadcrumb {
    padding: 15px 10px;
    .el-breadcrumb {
      font-size: 18px;
    }
  }
  .img-box {
    .images {
      overflow: hidden;
      background-color: #fff;
      border: 1px solid #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 148px;
      height: 148px;
      margin: 0 8px 8px 0;
      display: inline-block;
    }
  }
  .content {
    .content-top {
      display: flex;
      justify-content: center;
      align-items: center;
      .content-top-left {
        width: 100%;
        height: 350px;
        .bg-img {
          background-image: url('../../img/1.jpg');
          background-repeat: no-repeat;
          background-size: 100% 80%;
          width: 100%;
          height: 100%;
        }
      }
      .content-top-right {
        width: 100%;
        margin-left: 20px;
        height: 350px;
        .chart-table {
          text-align: center;
        }
        .chart-table tr:first-child td {
            color: #878787;
            border-top: 1px solid #DCDFE6;
        }
        .chart-table td {
          padding: 10px 33px;
          font-size: 14px;
          border-bottom: 1px solid #DCDFE6;
          border-right: 1px solid #DCDFE6;
        }
        .chart-table td:first-child {
          border-left: 1px solid #DCDFE6;
        }
        .left-name {
          color: #878787;
          background: #e8eaec;
        }
      }
    }
  }
  .real-time-curve-wrapper {
    width: 100%;
    #real-time-curve {
      height: 280px;
    }
  }
  </style>
  